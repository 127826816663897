import { useCookies } from 'react-cookie';
import './Cookiebanner.css'


export default function Cookiebanner(){
    const [cookies, setCookie] = useCookies(['cookie_banner']);
    if(cookies.cookie_banner !== "agreed" && cookies.cookie_banner !== "canceled"){
        return(
            <div className='padding-cookiebanner'>
                <p className='cookie-title'>Cookie-Einstellungen</p>
                <p className='cookie-desc'>Diese Website verwendet Cookies, um Ihnen ein besseres Surferlebnis zu bieten. Erfahren Sie mehr darüber, wie wir <a href='./cookie-richtlinie' className='link-cookies'>Cookies verwenden.</a></p>
                <div className="row">
                    <div className="col-6">
                        <button className='btn btn-success marg padding-btn' onClick={() => {
                              setCookie('cookie_banner', "agreed", {path:'/', maxAge:172800});
                              window.location.href="/";
                        }}>Akzeptiere alle Cookies</button>
                    </div>
                    <div className="col-6">
                        <button className='btn btn-danger padding-btn' onClick={() => {
                            setCookie('cookie_banner', "canceled", {path:'/', maxAge:172800});
                            window.location.href="/";
                        }}>Cookie ablehnen</button>
                    </div>
                </div>
            </div>
        );
    } else{
        return(
            <div></div>
        );
    }
    
}