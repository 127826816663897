import './Services.css';
import Inspection from '../../../images/inspektion.webp';
import Optimization from '../../../images/optimization.webp';
import Filter from '../../../images/prozess.webp';
import Pressure from '../../../images/pressure-gauge.webp';
import Bubble from '../../../images/bubble3.webp';
import React from 'react';
import { Element } from 'react-scroll';

export default function Services(){
    return(
        <React.Fragment>
            <Element id="services" name="contact">
                <div className="container-fluid">
                    <img src={Bubble} alt="a blurry blue bubble" className="bubble-service"/>
                    <div className="row marg-col-lg">
                        <div className="col-12 col-lg-4">
                            <h2 className="h2-services"><span className="underline-bg">Reparatur und Wartung</span> für Ihrer Strahlanlagen</h2>
                            <p className="p-services">Meine Strahlanlagen - Reparaturdienste sind darauf asugerichtet, Probleme mit Ihrer Strahlanlage zu beheben, damit Sie effizient arbeiten und optimale Ergebnisse liefern kann.</p>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="col-12 col-sm-6 padding-services">
                                    <div className="service-card text-center">
                                        <img src={Inspection} className="service-img" alt="an icon that illustrates ispection of a machine with a warning sign"/>
                                        <p className="service-title">Maschinendiagnose & Problemlösung</p>
                                        <p className="service-desc">Dazu können Defekte an Komponenten wie beschädigte Maschinenwände, Verschleissteile gehören.</p>
                                    </div>
                                    <div className="service-card text-center">
                                        <img src={Optimization} className="service-img" alt="an icon of a wrench optimising a machine"/>
                                        <p className="service-title">Ersatz defekter Komponenten</p>
                                        <p className="service-desc">Wenn fehlerhafte oder verbrauchte Komponenten festgestellt werden, werden sie durch neue Teile ersetzt.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 padding-services">
                                    <div className="service-card text-center">
                                        <img src={Filter} className="service-img" alt="an icon illustrating the process of repairing a machine"/>
                                        <p className="service-title">Überprüfung des Luftsystems & Filter wechseln</p>
                                        <p className="service-desc">Die Reparatur des Systems stellt sicher, dass die Luft mit dem richtigen Druck zugeführt wird.</p>
                                    </div>
                                    <div className="service-card text-center">
                                        <img src={Pressure} className="service-img" alt="an icon illustrating an air pressure mechanism"/>
                                        <p className="service-title">Einbau & Umbau von Strahlanlagen</p>
                                        <p className="service-desc">Installation einer Strahlanlage oder deren Umzug an einen anderen Standort.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="blue-descriptiv">** Ich führe auch die Prüfung der Maschine am Ende der Reparatur durch.</p>
                    </div>
                </div>
            </Element>
        </React.Fragment>
    );
}