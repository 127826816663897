import "./Footer.css";
import Logo from "../../images/logo-ism-oancea.png";
import Tel from "../../images/telephone.webp";
import Mail from "../../images/mail.webp";
import { Link } from "react-router-dom";
export default function Footer() {
    return(
        <footer className="container-footer row">
             <div className="col-lg-3 col-md-6 col-12 col-mg-bg">
                <p className="titles-footer">KONTAKT</p>
                <p className="descs-footer"><span><img src={Mail} alt="an orange email icon" className="contact-footer-img"/></span>contact@ism-oancea.de</p>
                <p className="descs-footer"><span><img src={Tel} alt="an orange phone icon" className="contact-footer-img"/></span>01520 3037 142</p>
             </div>
             <div className="col-lg-3 col-md-6 col-12 col-mg-bg">
                <p className="titles-footer">GDPR</p>
                <Link to="/datenschutz"><p className="descs-footer">Datenschutzerklärung</p></Link>
                <Link to="/cookie-richtlinie"><p className="descs-footer">Cookie Richtlinie</p></Link>
                <Link to="/impressum"><p className="descs-footer">Impressum</p></Link>
             </div>
             <div className="col-lg-3 col-md-6 col-12 col-mg-bg">
                <p className="titles-footer">SPRECHZEITEN</p>
                <p className="descs-footer">Montag bis Freitag</p>
                <p className="descs-footer">08:00 - 16:00</p>
             </div>
             <div className="col-lg-3 col-md-6 col-12 d-flex flex-lg-row ">
                <img src={Logo} alt="the ism-oancea logo consisting of a head of a man with a helmet inside a mechanical wheel " className="footer-logo"/>
                <div className="container-fluid text-margin-footer ">
                    <p className="titles-footer">ISM OANCEA</p>
                    <p className="descs-footer">Industrieservice <br/>& Montagen Spezialist</p>
                    <p className="descs-footer">@ 2023 alle Rechte vorbehalten</p>
                </div>
             </div>

        </footer>
    );
}