import './Nav.css';
import { Link } from 'react-router-dom';
import Scroll from 'react-scroll';
import Logo from '../../images/logo-ism-oancea.png';
import Telefon from  '../../images/telephone.webp';

export default function Nav(){
    if(window.location.href.includes("impressum") || window.location.href.includes("datenschutz") || window.location.href.includes("cookie-richtlinie")){
        return(
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        <img src={Logo} className="the ism-oancea logo consisting of a head of a man with a helmet inside a mechanical wheel" alt="Logo ISM"/>
                    </Link>
                    <button type="button" title='hamburger-menu' className="navbar-light navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav">
                            <Link to="/" className="nav-item nav-link active">startseite</Link>

                        </div>
                        <div className="navbar-nav ms-auto">
                            <Link to="/" className="nav-item nav-link"><span><img className="telefon-img" src={Telefon} alt="an orange phone icon"/>01520 3037 142</span></Link>
                        </div>
                    </div>
                </div>
            </nav>
         )
    }
    return(
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <Link to="#" className="navbar-brand">
                    <img src={Logo} className="the ism-oancea logo consisting of a head of a man with a helmet inside a mechanical wheel" alt="Logo ISM"/>
                </Link>
                <button type="button" className="navbar-light navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav">
                        <Link to="#" className="nav-item nav-link active">startseite</Link>
                        <Scroll.Link to="about-me" spy={true} smooth={true} duration={200} offset={-30} className="nav-item nav-link">über mich</Scroll.Link>
                        <Scroll.Link to="services" spy={true} smooth={true} duration={200} offset={-140} className="nav-item nav-link">leistungen</Scroll.Link>
                        <Scroll.Link to="contact" spy={true} smooth={true} duration={200} className="nav-item nav-link">kontakt</Scroll.Link>

                    </div>
                    <div className="navbar-nav ms-auto">
                        <Link to="#" className="nav-item nav-link"><span><img className="telefon-img" src={Telefon} alt="an orange phone icon"/>01520 3037 142</span></Link>
                    </div>
                </div>
            </div>
        </nav>
    );
}