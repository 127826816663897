import "./FaqEntry.css";
export default function FaqEntry (){
    return(
        <div className="accordion-container">
        <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                Wie viel kostet die Reparatur einer Strahlanlage?
                </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                <div className="accordion-body">
                    <p>Die Kosten für eine Strahlanlagenreparatur <span className="orange">hängen vom Ausmaß des Schadens</span>, dem Maschinentyp und den benötigten Ersatzteilen ab. Kleinere Reparaturen können ein paar hundert Euro kosten, während größere Reparaturen mehrere tausend Euro kosten können.</p>
                    <p>Als Spezialist für Strahlanlagen erstelle ich Ihnen vor Beginn einer Reparatur einen detaillierten Kostenvoranschlag.</p>
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                Woran erkenne ich, ob meine Strahlanlage repariert werden muss?
                </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                <div className="accordion-body">
                    <p>Wenn Sie beim Betrieb Ihrer Strahlanlage ungewöhnliche Geräusche, Vibrationen oder eine verminderte Leistung feststellen, muss sie möglicherweise repariert werden. Weitere Merkmale, auf die Sie achten sollten, sind beschädigte oder verschlissene Teile. </p>
                    <p>Regelmäßige Wartung und Inspektionen können helfen, Probleme frühzeitig zu erkennen und weitere Schäden an Ihrer Strahlanlage zu verhindern.</p>
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                Wie lange dauert die Reparatur einer Strahlanlage?
                </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                <div className="accordion-body">
                    <p>Wie lange es dauert, eine Strahlanlage zu reparieren, hängt vom Ausmaß des Schadens und der Verfügbarkeit von Ersatzteilen ab. Im Allgemeinen können kleinere Reparaturen innerhalb weniger Stunden oder eines Tages erledigt werden, während größere Reparaturen mehrere Tage oder sogar Wochen dauern können. </p>
                    <p>Als Spezialist für Strahlanlagen gebe ich Ihnen auf der Grundlage der ersten Inspektion der Strahlanlage einen voraussichtlichen Reparaturzeitraum an.</p>
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">

                Welche Qualifikationen und Erfahrungen habe ich?
                </button>
                </h2>
                <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                <div className="accordion-body">
                    <p>Als Spezialist für die Reparatur von Strahlanlagen verfüge ich über mehrjährige Erfahrung in der Reparatur und Wartung einer breiten Auswahl von Strahlanlagen, die in verschiedenen Industriebereichen eingesetzt werden. </p>
                    <p>Ich bin qualifiziert im Umgang mit Hydraulik und mechanischen Komponenten und verfüge daher über die erforderlichen Kenntnisse und Fähigkeiten, um Ihre Strahlanlage zu diagnostizieren und zu reparieren. </p>
                    <p>Meine Priorität ist es immer, sicherzustellen, dass Ihre Strahlanlage schnell und effizient repariert wird, um Ausfallzeiten zu minimieren und Ihren Betrieb so schnell wie möglich wieder in Gang zu bringen.</p>
                </div>
      
                </div>
            </div>
        </div>
    </div>
    )
}