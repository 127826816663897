import "./Faq.css";
import FaqEntry from "./FaqEntry";
import Bubble from "../../../images/bubble3.webp";
import Elipse from "../../../images/Ellipse 2.webp";
export default function Faq(){
    return(
        <div className="d-flex flex-wrap-reverse flex-lg-nowrap flex-lg-row justify-content-lg-around margin-faq">
            <img src={Bubble} alt="a colored bubble" className="bubble-faq"/>
            <img src={Elipse} alt="a colored orange ellipse" className="elipse-faq"/>
            <FaqEntry/>
            <div className="container-fluid">
                <div className="container-text-faq">
                    <h2 className="h2-faq"><span className="underline-bg">Ein paar weit</span>ere Fragen, die Sie vielleicht haben</h2>
                    <p className="p-faq">Für alle anderen Fragen stehe ich Ihnen von Montag bis Freitag telefonisch oder per E-Mail zur Verfügung.</p>
                </div>
            </div>
        </div>
    )
}