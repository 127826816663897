import './Header.css';
import IlustratieHomepage from '../../../images/hand-with-wrench.webp';
import Vector from '../../../images/Vector.webp';
import Scroll from 'react-scroll';
export default function Header(){
    return(
        <div>
            <div className="container-text-hero">
                <h1 className="h1-hero"><span className="underline-bg">Ihr Strahlanla</span>gen Wartung<br/>und Reparaturen Service</h1>
                <p className="p-hero">Durch meine Inspektions-, Wartungs- und Reparaturkenntnisse löse ich Ihre Strahlanlageprobleme, um Sie teure Schäden und Produktionsstillstände zu vermeiden.</p>
                <Scroll.Link to="contact" spy={true} smooth={true} duration={200} className='btn btn-large btn-cta'>Lassen Sie uns reden <img src={Vector} alt="white arrow that points right"/></Scroll.Link>
            </div>
            
            <img className="img-hero d-none d-sm-block" src={IlustratieHomepage} alt="drawing of a hand that holds a wrench"/>
        </div>
    )
}