import './ChoseSection.css';
import ElipseFirst from '../../../images/Ellipse 2.webp';
import ElipseSecond from '../../../images/Ellipse 4.webp';
import Rectangle from '../../../images/man-drawing.webp';
import Achievement from '../../../images/achievement-icon.webp';
import CustomerReview from '../../../images/customer-review.webp';
import Engineering from '../../../images/engineering-icon.webp';
import { Element } from 'react-scroll';
import React from 'react';
export default function ChoseSection(){
    return(
        <React.Fragment>
            <Element id="about-me" name="about-me">
                <div className="container-fluid">
                    <img  src={ElipseFirst} className="elipse-img-first" alt="an orange blurry ellipse"/>
                    <img src={ElipseSecond} className="elipse-img-sec" alt="a blue blurry ellipse"/>
                    <div >
                        <h2 className="h2-choose">
                        Ihre Problemlösung... 
                        <br/>im Mittelpunkt meiner Arbeit
                        </h2>
                        
                    </div>
                    <div className="row margin-row-chose ">
                        <div className="col-lg-5">
                            <img src={Rectangle} className="rectangle-img" alt="a drawing of a working man, standing and looking right with a helmet on his head"/>
                        </div>
                        <div className="col-lg-7 margin-col-text-chose">
                        <p className='p-choose'>Lassen Sie Ihre Sorgen zurück, denn ich kann Ihnen <span className='orange'>mit meinem Fachwissen helfen.</span></p>

                            <div className="choose-points">
                                <h3 className="h3-choose-title"><span className='span-blue'>1. </span>Unnötige Kosten und Zeit sparen</h3>
                                <p className="p-choose-description">
                                Ihre stagnierende Produktionslinien und defekte Strahlanlagen verursachen enorme Kosten und Zeitverluste.
                                </p>
                                <p className='p-choose-description'>
                                Ein frühzeitiger Termin zur Fehlersuche an Ihrer Strahlanlage mit mir reduziert Kosten und Ausfallzeiten. Ich verfüge über professionelle Werkzeuge und Kenntnisse für die meisten Strahlanlagen. 
                                </p>
                            </div>
                            <div className="choose-points">
                                <h3 className="h3-choose-title"><span className='span-blue'>2. </span>Überall, jederzeit für Sie da</h3>
                                <p className='p-choose-description'> 
                                Ersparen Sie sich die Suche nach einem Fachmann vor Ort und das Warten auf einen Notfalltermin. Genießen Sie die Mobilität und Zeitflexibilität, die ich Ihnen biete. 
                                </p>
                            </div>
                            <div className="choose-points">
                                <h3 className="h3-choose-title"><span className='span-blue'>3. </span>Der Reparaturprozess</h3>
                                <p className="p-choose-description">
                                Nach der Inspektion Ihrer Strahlanlage berate ich Sie herstellerneutral, welche Komponenten benötigt werden. Sie können diese selbst bestellen, und ich übernehme die Reparatur. 
                                
                                </p>
                                <p className="p-choose-description">
                                Anschließend führe ich eine Prüfung durch, um die ordnungsgemäße Funktion sicherzustellen.</p>
                            </div>
                        </div>
                    </div>
                    <div className='container-choose-experience d-flex justify-content-lg-between'>
                        
                        <div className='container-fluid text-center'>
                            <img src={Achievement} alt="an icon that represents achievement with a man and a medal" className="img-marg-exp"/>
                            <p className="p-experience-number">8</p>
                            <p className="p-experience-desc">Jahre<br/> Berufserfahrung</p>
                        </div>

                        <div className='container-fluid text-center'>
                            <img src={CustomerReview} alt="an icon that represents customer reviews with reviews stars" className="img-marg-exp"/>
                            <p className="p-experience-number">70</p>
                            <p className="p-experience-desc">zufriedene<br/> Kunden</p>
                        </div>

                        <div className='container-fluid text-center'>
                            <img src={Engineering} alt="an icon that represents an engineering wheel" className="img-marg-exp"/>
                            <p className="p-experience-number">87</p>
                            <p className="p-experience-desc">gewartete<br/> Maschinen</p>
                        </div>
                    </div>
                </div>
        </Element>
        </React.Fragment>
    )
}