import "./LetsTalk.css";
import Mail from "../../../images/mail.webp";
import Tel from "../../../images/telephone.webp";
import {Element} from 'react-scroll';
import React from "react";
import Bubble from '../../../images/bubble2.webp';
import Vektor from '../../../images/Vektor2.webp';
export default function LetsTalk(){
    return(
        <React.Fragment>
            <Element id="contanct" name="contact">
                <img src={Bubble} alt="an orange blurry bubble" className="bubble-contact"/>
                <img src={Vektor} alt="a curved arrow that points right" className="vektor-contact d-none "/>
                <div className="container-fluid contact-bg">
                    <div className="d-flex flex-wrap flex-sm-wrap flex-lg-nowrap justify-content-lg-between">
                        <div className="marg-col-lg">
                            <div className="container-text-center">
                                <h2 className="letstalk-h2">Braucht Ihre Strahlanlage eine Reparatur?</h2>
                                <p className="letstalk-p">Von der routinemäßigen Wartung bis hin zu größeren Reparaturen verfüge ich über das Fachwissen, um alle Arten von mechanischen Problemen an Strahlanlagen zu lösen. Lassen Sie nicht zu, dass eine defekte Strahlanlage Ihre Produktivität einschränkt.
                                </p>
                               <p></p>
                                <p className="letstalk-p">
                                Kontaktieren Sie mich noch heute für eine schnelle Wartung oder Reparatur.                               
                                </p>
                                <p className="letstalk-p orange-highlight">Lassen Sie uns jetzt reden! </p>
                            </div>
                        </div>
                        <div className="container-contact d-flex flex-column">

                            <div className="item-contact d-flex flex-row">
                                <img src={Mail} alt="orange e-mail icon" className="mail-img-contact"/>
                                <h4 className="contact-text">contact@ism-oancea.de</h4>
                            </div>
                            <div className="item-contact d-flex flex-row">
                                <img src={Tel} alt="orange phone icon" className="mail-img-contact"/>
                                <h4 className="contact-text">01520 3037 142</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
        </React.Fragment>
    );
}