import ChoseSection from "../../Components/Homepage/ChoseSection/ChoseSection";
import Faq from "../../Components/Homepage/Faq/Faq";
import Header from "../../Components/Homepage/Header/Header";
import LetsTalk from "../../Components/Homepage/LetsTalk/LetsTalk";
import Services from "../../Components/Homepage/Services/Services";
import Nav from "../../Components/Navbar/Nav";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";
import Cookiebanner from "../../Components/Cookiebanner/Cookiebanner";

export default function Home(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div>
            


            <Cookiebanner/>
            <Nav/>
            <Header/>
            <ChoseSection/>
            <Services/>
            <LetsTalk/>
            <Faq/>
            <Footer/>
        </div>
    )
}