import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home/Homepage';
import NotFound from './Pages/NotFound/NotFound';
import "./fonts/Quicksand-Bold.ttf";
import "./fonts/Quicksand-Light.ttf";
import "./fonts/Quicksand-Medium.ttf";
import "./fonts/Quicksand-Regular.ttf";
import "./fonts/Quicksand-SemiBold.ttf";
import Impressum from './Pages/Impressum/Impressum';
import Datenschutz from './Pages/Datenschutz/Datenschutz';
import Cookies from './Pages/Cookies/Cookies';

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home />}/>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/impressum" element={<Impressum/>}/>
          <Route path="/datenschutz" element={<Datenschutz/>}/>
          <Route path="/cookie-richtlinie" element={<Cookies/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
